<div (window:resize)="resizeWindow()">
  <div class="py-2 flex flex-col gap-4" *ngIf="hasSubscriptionEnabled">
    <app-label-secondary-color [label]="'input-labels.mode'"></app-label-secondary-color>
    <app-toggle-switch
      [leftLabel]="'input-labels.order' | translate"
      [rightLabel]="'input-labels.subscription' | translate"
      [value]="false"
      (valueChange)="onOrderModeChange($event)"
      [leftIcon]="faBox"
      [rightIcon]="faClock"
    ></app-toggle-switch>
  </div>
  <form (ngSubmit)="onSubmit()" [formGroup]="form" >
    <div *ngFor="let input of inputs; index as idx">
      <ng-container [ngSwitch]="input.controlType">
       
          <ng-container *ngIf="usePeriodInterface">
            <div *ngSwitchCase="'TOI'">
              <app-dynamic-input-toi
                *ngIf="input.key === 'toiStartDate'"
                [form]="form"
                [input]="input"
                [isVisible]="getVisible(input.key)"
                (userNewData)="onDateChanged($event, input.key)"
                [flagMax]="getMaxFlag(input.key)"
                [flagMin]="getMinFlag(input.key)"
                [newMaxDate]="getNewMaxDate(input.key)"
                [newMinDate]="getNewMinDate(input.key)"
                (dateSelected)="detectDate($event)"
                [timeLimit]="timeService"
                [isSubscription]="isSubscriptionContext"
              ></app-dynamic-input-toi>
              <app-dynamic-range-input
                *ngIf="input.key === 'toiEndDate'"
                [input]="input"
                [form]="form"
                [measureUnits]= "measureUnits"
                [dateSelected]="dateSelected"
                [maxServiceOrderLimit]="timeService?.max"
                [minServiceOrderLimit]="timeService?.min"
                [class]="isEnabled(input.key) ? '' : 'hidden'"
                [isSubscription]="isSubscriptionContext"
              ></app-dynamic-range-input>
            </div>
          </ng-container>
          <ng-container *ngIf="!usePeriodInterface">
            <app-dynamic-input-toi
              *ngSwitchCase="'TOI'"
              [form]="form"
              [input]="input"
              [isVisible]="getVisible(input.key)"
              (userNewData)="onDateChanged($event, input.key)"
              [flagMax]="getMaxFlag(input.key)"
              [flagMin]="getMinFlag(input.key)"
              [newMaxDate]="getNewMaxDate(input.key)"
              [newMinDate]="getNewMinDate(input.key)"
              [timeLimit]="timeService"
              [class]="isEnabled(input.key) ? '' : 'hidden'"
              [useMinDateAsDefaultMonth]="getMinFlag(input.key) && isStartDateSelected(input.key)"
              [isSubscription]="isSubscriptionContext"
            ></app-dynamic-input-toi>

            <app-icon-button
              *ngIf="input.key === 'toiEndDate' && hasDates()"
              (btnClick)="onDateReset()"
              [label]="'button-labels.clear-dates' | translate"
              [icon]="faTimes"
              [textColor]="'text-red-400'"
              type="button"
            ></app-icon-button>
          </ng-container>


        <app-dynamic-input-aoi
          *ngSwitchCase="'AOI'"
          [form]="form"
          [input]="input"
          [areaMeasureUnits]="measureUnits?.area"
          (newArea)="onNewArea($event)"
          [allowedGeometry]="allowedGeometry"
        >
        </app-dynamic-input-aoi>
        <app-dynamic-input
        *ngSwitchDefault
        [form]="form"
        [input]="input"
      ></app-dynamic-input>
      </ng-container>
    </div>
    <div class="flex flex-col justify-start pt-4" *ngIf="isSubscriptionContext">
      <app-label-secondary-color 
        [label]="'input-labels.timeStepSelect' | translate"
        [isRequired]="true"
      ></app-label-secondary-color>
      <div class="flex flex-row justify-start gap-4">
        <app-custom-select-input
          [multiple]="false"
          [items]="timeSteps.length > 0 ? timeSteps : [1]"
          class="w-24"
          formControlName="timeStep"
          id="timeStep"
        ></app-custom-select-input>
        <span class="my-auto">{{ 'input-labels.days' | translate}}</span>
      </div>
    </div>
    <ng-content></ng-content>

    <div class="flex items-center my-4">
      <!-- <input
        id="link-checkbox"
        type="checkbox"
        class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 focus:ring-2"
        formControlName="acceptedTermsAndConditions"
				required
      />
      <label
        for="link-checkbox"
        class="ml-2 text-sm font-medium text-gray-900"
        > {{ 'input-labels.terms-conditions.label' | translate }}
        <a [href]="termsURI" target="_blank" class="text-blue-600 hover:underline"
          >{{ 'input-labels.terms-conditions.link' | translate }}</a>.</label> -->
          <span>{{ 'input-labels.terms-conditions.label' | translate }} <a [href]="termsURI" target="_blank" class="text-blue-600 hover:underline"
            >{{ 'input-labels.terms-conditions.link' | translate }}</a></span>
    </div>
    <div class="flex justify-end mt-4">
      <app-button
        [isSecondary]="true"
        [isFull]="true"
        class="w-full"
        iconClasses="animate-spin"
        [leftIcon]="isLoading ? faSpinner : undefined"
        [isDisabled]="isLoading || !checkIsValid()"
        [label]="!isSubscriptionContext ? ('actions.order' | translate) : ('actions.subscription' | translate)"
        type="submit"
      ></app-button>
    </div>
  </form>
  <div class="flex flex-col justify-end gap-4 py-4">
    <p class="text-secondary-500">{{ 'main-page.orders.requestNewArea' | translate }}</p>
    <div class="flex flex-row">
      <app-button
        [label]="'button-labels.customized-request' | translate"
        (btnClick)="requestClicked()"
        [leftIcon]="faExternalLinkAlt"
      ></app-button>
      <app-icon-button
        [icon]="showHelp ? faQuestionCircleAlt : faQuestionCircle"
        (btnClick)="showHelp = !showHelp"
      ></app-icon-button>
    </div>
    <div *ngIf="showHelp" class="flex flex-col gap-1 border rounded-lg px-2 py-2 bg-gray-100">
      <p class="text-sm">{{'alerts.description.area-is-not-available.instructions' | translate}}</p>
      <li class="text-sm">{{'alerts.description.area-is-not-available.information-required.toi' | translate}}</li>
      <li class="text-sm">{{'alerts.description.area-is-not-available.information-required.aoi' | translate}}</li>
      <li class="text-sm">{{'alerts.description.area-is-not-available.information-required.other' | translate}}</li>
      <p class="text-sm">{{'alerts.description.area-is-not-available.submitting' | translate}}</p>
    </div>

  </div>

</div>
