import { NgClass } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventOption, Option, PaginationService } from '@core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnInit, OnChanges {
  perPageOptions = [18, 32, 48, 64];
  pages: number[] = [];

  serializedPerPageOptions: Option[] = this.perPageOptions.map((perPage) => ({
    label: perPage,
    value: perPage,
  }));


  @Input() classes: NgClass['ngClass'] = '';
  @Input() isLoading: boolean = false;

  @Output() currentPageChange = new EventEmitter();
  @Output() perPageChange = new EventEmitter();

  constructor(
    private activatedRoute: ActivatedRoute,
    private paginationService: PaginationService,
  ) {}

  get filteredPage() {
    let currentPage = this.paginationService.getCurrentPage
    const pages = this.pages.filter((page) => {
      if (page === 1) return true;
      if (page === this.pages.length) return true;

      if (currentPage >= page) {
        if (page > currentPage) return false;
        if (page >= currentPage - 1) return true;
      } else if (currentPage <= page) {
        if (page < currentPage) return false;

        if (page <= currentPage + 1) return true;
      }

      return false;
    });

    if (
      currentPage + 1 < this.pages.length &&
      currentPage + 2 !== this.pages.length
    )
      pages.splice(pages.length - 1, 0, -1);

    if (currentPage - 1 > 1 && currentPage - 2 !== 1)
      pages.splice(1, 0, -2);

    return pages;
  }

  ngOnChanges(): void {
    this.handleTotalPages();
    this.handleListOfPages();
  }

  ngOnInit(): void {
    this.handleTotalPages()
    this.handleListOfPages();
  }



  onPageChange(page: number) {
    if (page > 0)
      if (this.paginationService.getCurrentPage !== page) {
        this.paginationService.setCurrentPage = page;
        this.currentPageChange.emit();
      }
  }

  onPreviousPage() {
    if (this.paginationService.getCurrentPage > 1) {
      this.paginationService.setCurrentPage = this.paginationService.getCurrentPage - 1;
      this.currentPageChange.emit();
    }
  }

  onNextPage() {
    if (this.paginationService.getCurrentPage < this.paginationService.getTotalPages) {
      this.paginationService.setCurrentPage = this.paginationService.getCurrentPage + 1;
      this.currentPageChange.emit();
    }
  }

  onPerPageChange(target: EventOption) {
    this.paginationService.setCurrentPage = 1;
    this.paginationService.setItemsPerPage = target.value as 18 | 32 | 48 | 64;
    this.handleTotalPages();
    this.handleListOfPages();
    this.perPageChange.emit()
  }


  handleTotalPages() {
    this.paginationService.setTotalPages = Math.ceil(this.paginationService.getTotalResults / this.paginationService.getItemsPerPage);
  }

  handleListOfPages() {
    this.pages = Array.from({ length: this.paginationService.getTotalPages }, (_, idx) => idx + 1);
  }

  currentPageBgColorClass(page: number, isIndexable: boolean) {
    const isCurrentPage = page === this.paginationService.getCurrentPage;

    return {
      'text-gray-500 hover:text-white hover:bg-primary-400 cursor-pointer':
        isIndexable,
      'bg-primary-500 text-white': isCurrentPage,
      transparent: !isCurrentPage,
    };
  }

  get getCurrentPage() {
    return this.paginationService.getCurrentPage
  }

  get getItemsPerPage(){
    return this.paginationService.getItemsPerPage
  }

  get getTotalPages(){
    return this.paginationService.getTotalPages
  }

  get getTotalResults(){
    return this.paginationService.getTotalResults;
  }

}
