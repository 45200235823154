import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DynamicInputsControlService, INewOrder, MapService, Order, OrderExtraInput, Subtype, SnackbarService, TimeArea, TranslateService, UnitPrice, MeasureUnits, ServicePeriod } from '@core';
import { faCircleNotch, faSpinner, faTimes, faQuestionCircle, faExternalLinkAlt, faCalendarWeek, faCartShopping, faClock, faBox } from '@fortawesome/free-solid-svg-icons';
import { faQuestionCircle as faQuestionCircleAlt } from '@fortawesome/free-regular-svg-icons';
import { Observable, of, pipe, Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, mergeWith, takeUntil } from "rxjs/operators";
import { DynamicInputBase, TimeOfInterestInput } from '..';
import { addDays, addMonths, addWeeks, addYears, isBefore, subDays, subMonths, subWeeks, subYears } from 'date-fns';
import { convertSquareKMArea } from 'shared';


@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss'],
  // providers: [DynamicInputsControlService],
})
export class DynamicFormComponent implements OnInit, OnDestroy {
  @Input() isLoading: boolean = false;
  @Input() inputs: DynamicInputBase<any>[] | TimeOfInterestInput[] | null = [];
  @Input() isPriceGenerated: boolean = false;
  @Input() termsURI: string = '#';
  @Input() geoRecordSubType?: Subtype;
  @Input() timeService: TimeArea | undefined;
  @Input() areaService: TimeArea | undefined;
  @Input() unitPrice: UnitPrice | undefined;
  @Input() measureUnits: MeasureUnits | undefined;
  @Input() userData: any;
  @Input() maxServiceOrderLimit: number | undefined;
  @Input() minServiceOrderLimit: number | undefined;
  @Input() allowedGeometry: string | null | undefined;
  @Input() servicePeriod?: ServicePeriod;
  @Input() timeSteps: number[] = [];
  @Input() hasSubscriptionEnabled: boolean;

  @Output() submitForm = new EventEmitter<INewOrder>();
  @Output() action = new EventEmitter<string>();
  @Output() values = new EventEmitter<Order>();
  @Output() aoiToiChange = new EventEmitter();
  @Output() formReset = new EventEmitter();
  @Output() formChange = new EventEmitter<UntypedFormGroup>();
  @Output() areaExtRequest = new EventEmitter();

  faCircleNotch = faCircleNotch
  faSpinner = faSpinner
  faTimes = faTimes;
  faQuestionCircle = faQuestionCircle;
  faExternalLinkAlt = faExternalLinkAlt;
  faQuestionCircleAlt = faQuestionCircleAlt;
  faClock = faClock;
  faBox = faBox;

  isMobile: boolean = false;
  form!: UntypedFormGroup;
  componentDestroyed$: Subject<boolean> = new Subject();
  showHelp: boolean = false;

  newMaxDate: Date;
  newMinDate: Date;
  dateSelected: string;

  minFlagMap: Map<string, boolean> = new Map();
  maxFlagMap: Map<string, boolean> = new Map();
  visibleMap: Map<string, boolean> = new Map();

  isSubscriptionContext: boolean = false;

  constructor(
    private dynamicInputsControlService: DynamicInputsControlService,
    private map: MapService,
    private snackbarService: SnackbarService,
    private translateService: TranslateService
  ) {
  }

  ngOnInit() {
    this.minFlagMap.set('toiStartDate', false);
    this.minFlagMap.set('toiEndDate', false);
    this.maxFlagMap.set('toiStartDate', false);
    this.maxFlagMap.set('toiEndDate', false);
    this.visibleMap.set('toiStartDate', true);
    this.visibleMap.set('toiEndDate', true);
   
    if (this.inputs) {
      
      this.inputs.sort(function (a, b) {
        return a.order - b.order;
      });

      this.form = this.dynamicInputsControlService.toFormGroup(this.inputs);
      this.form.addControl('acceptedTermsAndConditions', new FormControl('', Validators.required));

      this.form.reset();
      if (this.form) {
        this.form.controls.acceptedTermsAndConditions.setValue(true);
        const valueChanges = pipe(
          takeUntil(this.componentDestroyed$),
          debounceTime(500),
          distinctUntilChanged()
        )

        this.form.valueChanges
          .pipe(valueChanges)
          .subscribe((data: any) => {
            if (data.toiStartDate && data.toiEndDate) {
              let startDate = new Date(data.toiStartDate);
              let stopDate = new Date(data.toiEndDate);
              if (startDate > stopDate) {
                this.form.setErrors({
                  invalidRange: true,
                });
              }

              const aoiChange = this.form.get('aoi')?.valueChanges as Observable<string>;
              const toiStartDateChange = this.form.get("toiStartDate")?.valueChanges as Observable<string>;
              data.toiEndDate = data.toiEndDate.replace("T00:00:00","T23:59:59");
          
              const toiEndDateChange = data.toiEndDate as Observable<string>;
          
              const aoiToiChange = of().pipe(mergeWith(aoiChange, toiStartDateChange, toiEndDateChange), valueChanges);

              aoiToiChange.subscribe(() => {
                const { aoi, toiStartDate: beginAt, toiEndDate: endAt } = this.form.value;
                const order = { aoi, beginAt, endAt };
                this.aoiToiChange.emit(order);
              });
            
            }
            this.values.emit(data);
            
          });

          
      }

    }
   
   
   

  }

  resizeWindow(){
      
    if(window.innerWidth > 1024 && this.isMobile == false)
    {
      this.isMobile=true;
      this.map.clearMap();
      this.formReset.emit(); 
    }

    if(window.innerWidth < 1024 && this.isMobile == true){

      this.isMobile = false;
      this.map.clearMap();
      this.formReset.emit(); 
    }

    
      
  }

  onSubmit() {

    let newOrder: INewOrder = {
      aoi: this.form.get('aoi')?.value,
      beginAt: this.form.get('toiStartDate')?.value,
      endAt: this.form.get('toiEndDate')?.value,
      inputs: new Array<OrderExtraInput>(),
      serviceId: null,
      isSubscription: this.isSubscriptionContext,
      timeStep: this.form.get('timeStep')?.value
    };
    Object.keys(this.form.controls).forEach((key) => {
      if (key !== 'aoi' && key !== 'toiStartDate'
        && key !== 'toiEndDate' && key !== 'timeStep') {
        newOrder.inputs?.push({
          identifier: key,
          value: this.form.get(key)?.value,
        });
      }
    });
    this.submitForm.emit(newOrder);

    
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  checkIsValid(): boolean {
    return this.form.valid && this.isPriceGenerated;
  }

  onDateChanged(date: Date, inputKey: string){
    if (inputKey === 'toiStartDate') {
      if (!this.form.get('toiEndDate')?.value && this.timeService) {
        this.visibleMap.set('toiEndDate', false);
        if (this.isSubscriptionContext) {
          this.setSubscriptionLimitDates(date, 'toiEndDate')
        } else {
          this.setLimitDates(date, 'toiEndDate');
        }
        setTimeout(() => {
          this.visibleMap.set('toiEndDate', true);
        }, 50);
      } else {
        this.visibleMap.set('toiEndDate', false);
        this.minFlagMap.set('toiEndDate', true);
        this.newMinDate = new Date(this.form.get('toiStartDate')?.value);
        setTimeout(() => {
          this.visibleMap.set('toiEndDate', true);
        }, 50);
      }
    } else if (inputKey === 'toiEndDate') {
      if (!this.form.get('toiStartDate')?.value && this.timeService) {
        this.visibleMap.set('toiStartDate', false);
        this.setLimitDates(date, 'toiStartDate');
        setTimeout(() => {
          this.visibleMap.set('toiStartDate', true);
        }, 50);
      }

    }
      
  }

  getMinFlag(inputKey: string) {
    return this.minFlagMap.get(inputKey);
  }

  getMaxFlag(inputKey: string) {
    return this.maxFlagMap.get(inputKey);
  }

  getNewMaxDate(inputKey: string) {
    if (this.maxFlagMap.get(inputKey)) {
      return this.newMaxDate
    } else {
      return undefined;
    }
  }

  getNewMinDate(inputKey: string) {
    if (this.minFlagMap.get(inputKey)) {
      return this.newMinDate;
    } else {
      return undefined;
    }
  }

  getVisible(inputKey: string) {
    return this.visibleMap.get(inputKey);
  }

  setLimitDates(date: Date, dateFormKey: string) {
    if (dateFormKey === 'toiEndDate') {
      if (this.timeService?.max !== undefined && this.servicePeriod?.serviceStop) {
        this.maxFlagMap.set(dateFormKey, true);
        switch (this.timeService.unit) {
          case 'DAY':
            if (isBefore(addDays(date, this.timeService.max), new Date(this.servicePeriod?.serviceStop))) {
              this.newMaxDate = addDays(date, this.timeService.max);
            } else {
              this.newMaxDate = this.servicePeriod?.serviceStop;
            }
            break;
          case 'WEEK':
            if (isBefore(addWeeks(date, this.timeService.max), new Date(this.servicePeriod?.serviceStop))){
              this.newMaxDate = addWeeks(date, this.timeService.max);
            } else {
              this.newMaxDate = this.servicePeriod?.serviceStop;
            }
          
            break;
          case 'MONTH':
              if (isBefore(addMonths(date, this.timeService.max), new Date(this.servicePeriod?.serviceStop))){
                this.newMaxDate = addMonths(date, this.timeService.max);
              } else {
                this.newMaxDate = this.servicePeriod?.serviceStop;
              }
            
            break;
          case 'YEAR':
            if (isBefore(addYears(date, this.timeService.max), new Date(this.servicePeriod?.serviceStop))) {
              this.newMaxDate = addYears(date, this.timeService.max);
            } else {
              this.newMaxDate = this.servicePeriod?.serviceStop;
            }
            break;
          default:
            break;
        }
      }
      if (this.timeService?.min !== undefined) {
        this.minFlagMap.set(dateFormKey, true);
        switch (this.timeService.unit) {
          case 'DAY':
            this.newMinDate = addDays(date, this.timeService.min);
            break;
          case 'WEEK':
            this.newMinDate = addWeeks(date, this.timeService.min);
            break;
          case 'MONTH':
            this.newMinDate = addMonths(date, this.timeService.min);
            break;
          case 'YEAR':
            this.newMinDate = addYears(date, this.timeService.min);
            break;
          default:
            break;
        }
      }
    } else {
      if (this.timeService?.max !== undefined) {
        this.minFlagMap.set(dateFormKey, true);
        switch (this.timeService.unit) {
          case 'DAY':
            this.newMinDate = subDays(date, this.timeService.max);
            break;
          case 'WEEK':
            this.newMinDate = subWeeks(date, this.timeService.max);
            break;
          case 'MONTH':
            this.newMinDate = subMonths(date, this.timeService.max);
            break;
          case 'YEAR':
            this.newMinDate = subYears(date, this.timeService.max);
            break;
          default:
            break;
        }
      }
      if (this.timeService?.min !== undefined) {
        this.maxFlagMap.set(dateFormKey, true);
        switch (this.timeService.unit) {
          case 'DAY':
            this.newMaxDate = subDays(date, this.timeService.min);
            break;
          case 'WEEK':
            this.newMaxDate = subWeeks(date, this.timeService.min);
            break;
          case 'MONTH':
            this.newMaxDate = subMonths(date, this.timeService.min);
            break;
          case 'YEAR':
            this.newMaxDate = subYears(date, this.timeService.min);
            break;
          default:
            break;
        }
      }
    }
    
  }

  onDateReset() {
    this.form.get('toiStartDate')?.reset();
    this.form.get('toiEndDate')?.reset();
    this.form.updateValueAndValidity();
    this.maxFlagMap.set('toiStartDate', false);
    this.maxFlagMap.set('toiEndDate', false);
    this.minFlagMap.set('toiStartDate', false);
    this.minFlagMap.set('toiEndDate', false);
    this.visibleMap.set('toiStartDate', false);
    this.visibleMap.set('toiEndDate', false);
    setTimeout(() => {
      this.visibleMap.set('toiStartDate', true);
      this.visibleMap.set('toiEndDate', true);

    }, 50);
  }

  onNewArea(area: number) {
    if (area && this.areaService && this.areaService.unit) {
      const convertedArea = convertSquareKMArea(area, this.areaService.unit);
      if (this.areaService.min && convertedArea < this.areaService.min) {
        this.form.get('aoi')?.setValue(null);
        this.form.updateValueAndValidity();
        //emit error
        const title = this.translateService.translate('alerts.title.error');
        const message = this.translateService.translate('errors.area-limit-low') + 
          ' Min: ' + this.areaService.min + ' ' + 
          this.translateService.translate('labels.area-units.' + this.areaService.unit);
        this.snackbarService.danger(title, message).during(5000).show();
      }
      if (this.areaService.max && convertedArea > this.areaService.max) {
        
        this.form.get('aoi')?.setValue(null);
        this.form.updateValueAndValidity();
        //emit error
        const title = this.translateService.translate('alerts.title.error');
        const message = this.translateService.translate('errors.area-limit-high') +
          ' Max: ' + this.areaService.max + ' ' + 
          this.translateService.translate('labels.area-units.' + this.areaService.unit);
        this.snackbarService.danger(title, message).during(5000).show();
      }
      
    }
  }


  hasDates() {
    return this.form.get('toiStartDate')?.value || this.form.get('toiEndDate')?.value;
  }

  requestClicked() {
    this.areaExtRequest.emit();
  }
  
  get usePeriodInterface(): boolean { 
    
    return (this.unitPrice?.time !== undefined || this.unitPrice?.timeArea !== undefined ) &&
      this.measureUnits !== undefined && 
      (this.measureUnits.time === 'MONTH' || this.measureUnits.time === 'YEAR') &&
      !this.userData.beta;
  }

  detectDate(event:any){
    this.dateSelected = event;
  }

  isEnabled(inputKey: string): boolean {
    if (inputKey === 'toiEndDate') {
      return this.form.get('toiStartDate')?.value;
    } else {
      return true;
    }
  }

  isStartDateSelected(inputKey: string): boolean {
    return inputKey === 'toiEndDate' && this.form.get('toiStartDate')?.value !== undefined;
  }

  onOrderModeChange(flag: boolean) {
    this.isSubscriptionContext = flag;
    this.visibleMap.set('toiStartDate', false);
    this.visibleMap.set('toiEndDate', false);
    setTimeout(() => {
      this.form.get('toiStartDate')?.reset();
      this.form.get('toiEndDate')?.reset();
      this.form.updateValueAndValidity();
      this.visibleMap.set('toiStartDate', true);
    }, 100);
    if (flag) {
      this.form.addControl('timeStep', new FormControl('', Validators.required));
    } else {
      this.form.removeControl('timeStep');
    }
    this.form.updateValueAndValidity();
  }

  setSubscriptionLimitDates(date: Date, dateFormKey: string) {
    if (dateFormKey === 'toiEndDate') {
      if (this.timeService?.max !== undefined) {
        this.maxFlagMap.set(dateFormKey, true);
        switch (this.timeService.unit) {
          case 'DAY':
            this.newMaxDate = addDays(date, this.timeService.max);
            break;
          case 'WEEK':
            this.newMaxDate = addWeeks(date, this.timeService.max);
            break;
          case 'MONTH':
            this.newMaxDate = addMonths(date, this.timeService.max);
            break;
          case 'YEAR':
            this.newMaxDate = addYears(date, this.timeService.max);
            break;
          default:
            break;
        }
      }
      if (this.timeService?.min !== undefined) {
        this.minFlagMap.set(dateFormKey, true);
        switch (this.timeService.unit) {
          case 'DAY':
            this.newMinDate = addDays(date, this.timeService.min);
            break;
          case 'WEEK':
            this.newMinDate = addWeeks(date, this.timeService.min);
            break;
          case 'MONTH':
            this.newMinDate = addMonths(date, this.timeService.min);
            break;
          case 'YEAR':
            this.newMinDate = addYears(date, this.timeService.min);
            break;
          default:
            break;
        }
      }
    } else {

    }
  }

}
