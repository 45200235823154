<div [ngClass]="classes"
     class="flex flex-col sm:flex-row items-center justify-between">
	<div *ngIf="pages.length !== 0; else noData">
		{{ "main-page.pagination.showing" | translate }}
		{{ "main-page.pagination.page" | translate }}
		{{ getCurrentPage }}
    {{ "main-page.pagination.to" | translate }} {{ getTotalPages }}
		{{ "main-page.pagination.of" | translate }} {{ getTotalResults }}
    {{ "main-page.pagination.results" | translate }}
	</div>
	<ng-template #noData>
		<p class="text-gray-500">
      <span *ngIf="isLoading;else notLoading" class="animate-pulse">{{"actions.loading.data" | translate}}</span>
      <ng-template #notLoading>{{ "not-found.pagination.no-data" | translate }}</ng-template>
    </p>
	</ng-template>
	<div class="flex items-center gap-8">
		<div class="flex text-gray-700">
			<div
					(click)="onPreviousPage()"
					class="
          h-8
          w-8
          mr-1
          flex
          justify-center
          items-center
          rounded-lg
          bg-gray-100
          text-secondary-500
          hover:text-white
          hover:bg-primary-500
          duration-200
          cursor-pointer
        "
			>
				<svg
						class="feather feather-chevron-left w-4 h-4"
						fill="none"
						height="100%"
						stroke="currentColor"
						stroke-linecap="round"
						stroke-linejoin="round"
						stroke-width="2"
						viewBox="0 0 24 24"
						width="100%"
						xmlns="http://www.w3.org/2000/svg"
				>
					<polyline points="15 18 9 12 15 6"></polyline>
				</svg>
			</div>
			<div class="flex h-8 font-medium rounded-lg bg-gray-100">

				<div
						(click)="onPageChange(page)"
						*ngFor="let page of filteredPage"
						[ngClass]="currentPageBgColorClass(page, page > 0)"
						class="
          w-8
          md:flex
          justify-center
          items-center
          hidden
          leading-5
          transition
          duration-200
          ease-in
          rounded-lg
        ">
					<p *ngIf="page < 0;else isIndexable" class="text-gray-500">
						...</p>
					<ng-template #isIndexable>
						{{ page }}
					</ng-template>
				</div>
			</div>
			<div
					(click)="onNextPage()"
					class="
          h-8
          w-8
          ml-1
          flex
          justify-center
          items-center
          rounded-lg
          bg-gray-100
          duration-200
          hover:bg-primary-500
          cursor-pointer
          text-secondary-500 hover:text-white
        "
			>
				<svg
						class="feather feather-chevron-right w-4 h-4 "
						fill="none"
						height="100%"
						stroke="currentColor"
						stroke-linecap="round"
						stroke-linejoin="round"
						stroke-width="2"
						viewBox="0 0 24 24"
						width="100%"
						xmlns="http://www.w3.org/2000/svg"
				>
					<polyline points="9 18 15 12 9 6"></polyline>
				</svg>
			</div>
		</div>
		<app-select
        [isDisabled]="isLoading || !perPageOptions.includes(getItemsPerPage)"
				(propagate)="onPerPageChange($event)"
				[clearable]="false"
				[options]="serializedPerPageOptions"
				[required]="true"
				[value]="getItemsPerPage"
				class="mt-2"
		></app-select>
	</div>
</div>
