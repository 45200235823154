<nav class = "z-50 relative py-2 h-14 {{scrollTop > 233?'bg-transparent':'bg-white shadow-md'}}">
	<div class = "w-full mx-auto px-4 pb-2">
		<div class = "flex justify-between">
			<div class = "flex w-full justify-between z-50">
				<div class = "shrink-0 flex">
					<a
						class = "flex items-center px-2"
						href = "#"
					>
						<svg-icon
							src = "assets/img/logos/logo-navbar.svg"
							svgClass = "h-10 w-40"
						></svg-icon>
					</a>
					<a *ngIf="!env.production"
					   class="absolute z-50 bg-danger-500 top-0 left-48 px-4 rounded-b-full shadow-md hover:px-5 active:scale-x-95 duration-300"
					   href="https://jira.elecnor-deimos.com/projects/SERV4EO?selectedItem=com.atlassian.jira.jira-projects-plugin%3Arelease-page&status=unreleased"
					   target="_blank">
						<span class=" text-sm text-gray-50">v{{pkg.version}}</span>
					</a>
				</div>
				<div
					class = "hidden md:flex items-center justify-center space-x-4 w-10/12"
				>
					<app-navbar-link
						[label] = "'main-page.navigation.home' | translate"
						route = "/home"
					></app-navbar-link>
					<app-navbar-link
						[label] = "'main-page.navigation.services' | translate"
						route = "/customers/marketplace"
					></app-navbar-link>
					<ng-template [ngxPermissionsOnly] = "['customer', 'seller']">
						<app-navbar-link
							[label] = "'main-page.navigation.my-orders' | translate"
							route = "/customers/orders"
						>
						</app-navbar-link>
					</ng-template>
					<ng-template ngxPermissionsOnly = "seller">
						<app-navbar-link
							[label] = "'main-page.navigation.my-services' | translate"
							route = "/sellers/services"
						></app-navbar-link>
						<app-navbar-link
							[label] = "'main-page.navigation.new-service' | translate"
							route = "/sellers/new-service"
						></app-navbar-link>
					</ng-template>
					<app-navbar-link
						[label] = "'main-page.navigation.resources' | translate"
						route = "/home/resources"
					></app-navbar-link>
					<app-navbar-link
						[label] = "'main-page.navigation.support' | translate"
						[link]="env.externalLinks.support"
					></app-navbar-link>
				</div>
				<div class = "hidden md:flex shrink-0 items-center space-x-3">
					<div class = "flex shrink-0 space-x-8 justify-end">
						<button
							(click) = "setLang()"
							[title] = "'main-page.tooltip.choose-language' | translate"
							class = "mr-2"
						>
							<div
								class = "grid grid-cols-2 divide-x-2 hover:bg-gray-100 duration-300 py-1 px-1 rounded"
							>
								<span class = "px-2" [ngClass]="languageClass('font-normal', 'font-bold')">PT</span>
								<span class = "px-2" [ngClass]="languageClass('font-bold', 'font-normal')">EN</span>
							</div>
						</button>
					</div>

					<div *ngIf = "this.isAuthenticated; else signInButton">
						<button
							(click) = "isShowingProfileCard = !isShowingProfileCard"
							*ngIf = "( oidcSecurityService.getUserData() | async ) as user"
							class = "
                bg-gradient-to-r
                from-primaryGradient-right
                to-primaryGradient-left
                rounded-full
                h-10
                w-10
                transform
                duration-300
                ease-in-out
                hover:cursor-pointer
                motion-safe:hover:scale-105 motion-safe:active:scale-100
                z-50
              "
						>
							<h5 class = "m-0 text-gray-50 font-bold text-sm">
								{{ getInitials(user.displayName) }}
							</h5>
						</button>
					</div>
					<ng-template #signInButton>
						<app-button
							(btnClick) = "login()"
							[label] = "'auth.sign-in' | translate"
							[leftIcon] = "signIn"
						></app-button>
					</ng-template>
				</div>
			</div>
			<div class = "md:hidden flex items-center">
				<button
					(click) = "isMenuOpen = !isMenuOpen"
					class = "outline-none mobile-menu-button"
				>
					<svg
						class = "w-6 h-6 text-gray-500"
						fill = "none"
						stroke = "currentColor"
						stroke-linecap = "round"
						stroke-linejoin = "round"
						stroke-width = "2"
						viewBox = "0 0 24 24"
						x-show = "!showMenu"
					>
						<path d = "M4 6h16M4 12h16M4 18h16"></path>
					</svg>
				</button>
			</div>
		</div>
	</div>
	<div 
		class="flex w-full bg-red-500 text-white justify-center p-2" 
		*ngIf="serviceNotAvailable !== undefined && serviceNotAvailable?.attributes?.Message?.length !== null"
	>
		{{ serviceNotAvailable.attributes.Message }}
	</div>
</nav>
<div
	*ngIf = "isMenuOpen && isMobile"
	[@mobileMenu] = "isMenuOpen"
	class = "z-20 duration-300"
>
	<ul>
		<li>
			<app-navbar-link
				[isMobile] = "true"
				[label] = "'main-page.navigation.home' | translate"
				route = "/home"
			></app-navbar-link>
		</li>
		<li>
			<app-navbar-link
				[isMobile] = "true"
				[label] = "'main-page.navigation.services'| translate"
				route = "/customers/marketplace"
			></app-navbar-link>
		</li>
		<ng-template [ngxPermissionsOnly] = "['customer', 'seller']">
			<li>
				<app-navbar-link
					[isMobile] = "true"
					[label] = "'main-page.navigation.my-orders'| translate"
					route = "/customers/orders"
				>
				</app-navbar-link>
			</li>
		</ng-template>
		<ng-template ngxPermissionsOnly = "seller">
			<li>
				<app-navbar-link
					[isMobile] = "true"
					[label] = "'main-page.navigation.my-services' | translate"
					route = "/sellers/services"
				></app-navbar-link>
			</li>
			<li>
				<app-navbar-link
					[isMobile] = "true"
					[label] = "'main-page.navigation.new-service' | translate"
					route = "/sellers/new-service"
				></app-navbar-link>
			</li>
		</ng-template>
		<li>
			<app-navbar-link
				[isMobile] = "true"
				[label] = "'main-page.navigation.resources' | translate"
				route = "/home/resources"
			></app-navbar-link>
		</li>
		<li>
			<app-navbar-link
				[isMobile] = "true"
				[label] = "'main-page.navigation.support' | translate"
				[link]="env.externalLinks.support"
			></app-navbar-link>
		</li>
		<li>
			<div
				*ngIf = "
          this.isAuthenticated && (oidcSecurityService.getUserData() | async ) as user;
          else signInButton
        "
			>
				<div class = "flex flex-1 justify-between items-start">
					<div class = "m-2 flex justify-between w-full items-end">
						<div class = "text-container">
							<h5>{{ user.name }}</h5>
							<span>{{ user.email }}</span>
						</div>
						<app-icon-button
							(click) = "signOut()"
							[icon] = "signOutIcon"
							[label] = "'auth.sign-out' | translate"
							hover = "hover:text-red-500"
							textColor = "text-gray-500"
						></app-icon-button>
					</div>
				</div>
			</div>
			<ng-template #signInButton>
				<div class = "flex items-center justify-center py-2 ">

					<app-button
						(btnClick) = "login()"
						[label] = "'auth.sign-in' | translate"
						[leftIcon] = "signIn"
						type = "button"
					></app-button>
				</div>
			</ng-template>
		</li>
	</ul>
</div>
<div
	(outsideClick) = "isShowingProfileCard = !isShowingProfileCard"
	*ngIf = "isShowingProfileCard"
>
	<div
		*ngIf = "(oidcSecurityService.getUserData() | async ) as user"
		[@slideInOut]
		class = "
      z-50
      flex shrink-0
      absolute
      m-4
      right-12
      bg-white
      rounded
      shadow-md
      w-auto
    "
	>
		<div
			class = "
        flex
        justify-center
        bg-gradient-to-r
        from-primary-500
        to-primary-600
        mr-4
        px-4
        w-20
        rounded-l
        items-center
      "
		>
			<h1 class = "m-0 text-gray-50 text-3xl font-bold">
				{{ getInitials(user.displayName) }}
			</h1>
		</div>
		<div class = "flex flex-1 justify-between items-start">
			<div class = "m-2">
				<div class = "text-container">
					<h5>{{ user.displayName }}</h5>
					<span>{{ user.email }}</span>
				</div>
        <button class="mt-2 flex flex-row items-center" (click)="onBecomeASellerClick()" *ngxPermissionsOnly = "['customer']">
          <fa-icon [icon]="faUserTag" class="text-primary-500 mr-1"></fa-icon>
          <h5 class="text-primary-500 text-sm">{{'actions.become-a-seller' | translate}}</h5>
        </button>
			</div>
			<div
				class = "flex flex-1 justify-between flex-col px-2 py-2 h-full items-end mt-1"
			>
				<app-icon-button
					(click) = "isShowingProfileCard = !isShowingProfileCard"
					[icon] = "close"
					hover = "hover:text-red-500"
					textColor = "text-gray-500"
				></app-icon-button>
				<app-icon-button
					(click) = "signOut()"
					[icon] = "signOutIcon"
					[label] = "'auth.sign-out' | translate"
					hover = "hover:text-red-500"
					textColor = "text-gray-500"
				></app-icon-button>
			</div>
		</div>
	</div>
</div>

<app-dialog
  *ngIf="isBecomeASellerClicked"
  (cancel)="isBecomeASellerClicked = !isBecomeASellerClicked"
  size="md"
>
  <div class="text-sm text-gray-500">
    <h1 class="font-semibold text-2xl mb-4">{{ 'labels.become-a-seller-title' | translate }}</h1>
    <div innerHtml="{{ (becomeSeller$ | async)?.data?.attributes?.termsAndConditions }}"></div>
  </div>
  <div class="flex justify-end mt-4">
    <app-button
      [isSecondary]="true"
      [isFull]="true"
      class="w-full"
      iconClasses="animate-spin"
      [label]="'actions.become-a-seller-submit' | translate"
      (btnClick)="requestBecomeSeller()"
      ></app-button>
  </div>
</app-dialog>
