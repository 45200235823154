import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import * as pkg from '../../../../../../package.json';
import {IconDefinition} from "@fortawesome/free-solid-svg-icons";
import {faLinkedinIn, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { AuthService } from '@core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  env = environment;
  year = new Date().getFullYear();
  logos: Array<{
    fileName: string;
    alt: string;
    type: string;
    url?: string;
  }> = [
    {
      fileName: 'deimos-blue.svg',
      alt: 'Deimos - logo',
      type: 'svg',
      url: this.env.externalLinks.deimos,
    },
    {
      fileName: 'pml.svg',
      alt: 'pml - logo',
      type: 'svg',
      url: this.env.externalLinks.pml,
    },
    {
      fileName: 'nova.svg',
      alt: 'nova - logo',
      type: 'svg',
      url: this.env.externalLinks.nova,
    },
    {
      fileName: 'terra.svg',
      alt: 'terra-due - logo',
      type: 'svg',
      url: this.env.externalLinks.terra,
    },
    {
      fileName: 'sintef_ocean.svg',
      alt: 'sintef_ocean - logo',
      type: 'svg',
      url: this.env.externalLinks.sintef_ocean,
    },
    {
      fileName: 'sintef_nord.svg',
      alt: 'sintef_nord - logo',
      type: 'svg',
      url: this.env.externalLinks.sintef_nord,
    },
    {
      fileName: 'ipma.png',
      alt: 'ipma - logo',
      type: 'img',
      url: this.env.externalLinks.ipma,
    },
    {
      fileName: 'air.png',
      alt: 'air - logo',
      type: 'img',
      url: this.env.externalLinks.air,
    },
    {
      fileName: 'cls.svg',
      alt: 'cls - logo',
      type: 'svg',
      url: this.env.externalLinks.cls
    },
    {
      fileName: 'colab_atlantic.svg',
      alt: 'colab-atlantic - logo',
      type: 'svg',
      url: this.env.externalLinks.colabAtlantic
    },
    {
      fileName: 'amn.png',
      alt: 'amn - logo',
      type: 'img',
      url: this.env.externalLinks.amn
    }
  ];
  pkg = pkg
  faTwitter: IconDefinition = faTwitter;
  faLinkedinIn: IconDefinition = faLinkedinIn
  faYoutube: IconDefinition = faYoutube

  constructor(private authService: AuthService) {}

  ngOnInit(): void {}

  get isLoggedIn() {
    return this.authService.isLoggedIn;
  }
}
